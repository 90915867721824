<template>
  <div class="pb-6 mb-6">
    <hero-bar>
      <router-link :to="{name:'playtours'}" slot="" class="button is-primary is-light mr-2">
        <i class="fas fa-chevron-left mr-2"></i>
        All games
      </router-link>
      {{ adventure.details.adventure_details.name }}
    </hero-bar>
    <!-- <section class="section is-main-section pb-1">
      <manage-play-tour-form :adventure="adventure" class="tile is-child" />
    </section> -->

    <G2Requester />

    <section class="section is-main-section pb-0">
      <div class="level p-0">
        <div class="level-left">
          <!-- <button @click="openWelcomeScreen()" class="button is-primary is-outlined mr-1">
            <i class="fas fa-home mr-2"></i>
            View welcome screen
          </button>
          <button @click="openTestGame()" class="button is-primary is-outlined mr-1">
            <i class="fas fa-vial mr-2"></i>
            Test
          </button> -->
          <button
            @click="openGameBuilder"
            class="button is-primary mr-2"
          >
            <i class="fas fa-pencil-ruler mr-2"></i>
            Open game builder
          </button>
          <button
          slot="right"
          @click="duplicatePlayTour"
          class="button is-primary is-light mr-2"
          :class="{ 'is-loading': loading }"
          >
            <i class="fas fa-copy"></i>
          </button>
          <button
            slot="right"
            @click="deletePlayTour"
            class="button is-danger is-light"
            :class="{ 'is-loading': loading }"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
        <div class="level-left">
          <div class="item"></div>
        </div>
      </div>
    </section>

    <section class="section is-main-section pb-0 pt-5">
      <card-component title="What are sessions?" icon="fa-info-circle">
        <div class="is-size-7">
          <span class="tag is-rounded is-light">1</span> Using sessions allows you to keep teams and players data separate from 1 event to another i.e.
          creating a new session will create an entirely new game code, leaderboard, etc.
          <br>
          <span class="tag is-rounded is-light">2</span> Create a new session to test your game out, and a separate session for your actual event.
          <br>
          <span class="tag is-rounded is-light">3</span> Sessions do not expire and can be used for events of any length of time.
          <br>
          <span class="tag is-rounded is-light">4</span> To prevent players from playing the game before the actual event, set "Is active" to "No" when creating/editing the session.
        </div>
      </card-component>
    </section>

    <section class="section is-main-section pb-1 pt-2">
      <div class="level mb-2">
        <div class="level-left">
          <div class="level-item">
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <router-link
              :to="{name:'addSession', params: { adventureId }}"
              class="button is-primary"
            >
             <i class="fas fa-plus mr-2"></i>
              Add session
            </router-link>
          </div>
        </div>
        <!-- <div class="level-right">
          <div class="level-item has-text-weight-semibold mr-0">
            Show inactive sessions
          </div>
          <div class="level-item">
            <b-field label="" horizontal>
              <b-switch v-model="showInactiveSessions">
              </b-switch>
            </b-field>
          </div>
        </div> -->
      </div>
      <card-component
      class="has-table has-mobile-sort-spaced"
      title="Sessions"
      icon="fa-calendar-day"
      >
        <sessions-table
          :sessions="sessionsToShow"
          :checkable="false"
        />
      </card-component>
    </section>

    <section v-if="adventure.accessType == 'public'" class="section is-main-section">
      <card-component
        v-if="adventureId"
        class="has-table has-mobile-sort-spaced"
        title="Non-session Leaderboard"
        icon="fa-stream"
      >
        <leaderboard :adventureId="adventureId" class="tile is-child" />
      </card-component>
    </section>

    <AdventureVisibility :adventureId="adventure.id"></AdventureVisibility>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
// import ManagePlayTourForm from '@/components/ManagePlayTourForm'
import Leaderboard from '@/components/Leaderboard'
import SessionsTable from '@/components/SessionsTable'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import AdventureVisibility from '../components/AdventureVisibility.vue'
import G2Requester from '@/components/G2Requester'

export default {
  name: 'PlayTourEdit',
  components: {
    HeroBar,
    SessionsTable,
    // ManagePlayTourForm,
    CardComponent,
    Leaderboard,
    AdventureVisibility,
    G2Requester
  },
  data () {
    return {
      loading: false,
      adventureId: this.$route.params.adventureId,
      sessions: null,
      showInactiveSessions: true,
      gameBuilderActivity: null
    }
  },
  methods: {
    openGameBuilder () {
      if (
        this.gameBuilderActivity &&
        this.gameBuilderActivity.activities.some(x => {
          return (
            // x.judgementId === judgementId &&
            x.uid !== this.$store.state.user.uid.slice(-5) &&
            (new Date().getTime()) - (x.createdAt.nanoseconds / 1000000 + x.createdAt.seconds * 1000) < 60000
          )
        })
      ) {
        const confirmed = confirm(
          'WARNING! Another admin is currently in the game builder. Making changes will override the changes made by them. Please request all other admins to close the game builder. Do you still want to continue?')
        if (!confirmed) return
      }
      this.$router.push({ name: 'editPlayTour' })
    },
    openWelcomeScreen () {
      const confirmed = confirm('This is the welcome screen preview, for games where players can create teams on their own. If you want to give pre-defined teams, go to the "Teams" section inside a session and give them the Team Link instead.')
      if (!confirmed) { return }
      const link = `${this.$store.state.baseUrl}/play/join/${this.adventureId}/`
      window.open(link)
    },
    openTestGame () {
      const confirmed = confirm('Testing will limit the game time to 10 minutes. It will override your configured game time, if any. All location checks are removed, so you can view tasks that are location-based wherever you are.')
      if (!confirmed) { return }
      const link = `${this.$store.state.baseUrl}/play/join/${this.adventureId}/TESTING-1`
      window.open(link)
    },
    duplicatePlayTour () {
      this.loading = true
      const confirmed = confirm('This will create a copy of this game. Confirm?')
      if (!confirmed) {
        this.loading = false
        return
      }
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'duplicate-adventure',
        adventureId: this.adventureId
      }).then(res => {
        this.loading = false
        this.$buefy.toast.open({
          message: 'Successfully duplicated!',
          type: 'is-primary',
          queue: false
        })
        this.$router.push({ name: 'playtours' })
      }).catch(() => {
        this.loading = false
      })
    },
    deletePlayTour () {
      this.loading = true
      const confirmed = confirm(`Confirm DELETION of "${this.adventure.details.adventure_details.name}"?`)
      if (
        confirmed &&
        prompt('THERE IS NO BACKUP CREATED. Deletion cannot be undone. Type DELETE to continue.').toUpperCase() !== 'DELETE'
      ) {
        return
      }
      if (!confirmed) {
        this.loading = false
        return
      }
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'delete-adventure',
        adventureId: this.adventureId
      }).then(() => {
        this.loading = false
        this.$buefy.toast.open({
          message: 'Successfully deleted!',
          type: 'is-primary',
          queue: false
        })
        this.$router.push({ name: 'playtours' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  watch: {
    adventureId: {
      immediate: true,
      handler (adventureId) {
        const sessions = firebaseApp.firestore().collection('sessions')
        if (adventureId) {
          this.$bind('sessions', sessions.where('organisationId', '==', this.profile.organisationId).where('adventureId', '==', this.adventureId))
        }
      }
    }
  },
  computed: {
    adventure () {
      return this.$store.state.adventures.filter(el => {
        return el.id === this.adventureId
      })[0]
    },
    sessionsToShow () {
      if (!this.sessions) {
        return []
      } else {
        let sessionsToShow = [...this.sessions]
        if (!this.showInactiveSessions) {
          sessionsToShow = this.sessions.filter(el => {
            return el.isActive
          })
        }
        sessionsToShow.sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            if (a.createdAt < b.createdAt) {
              return 1
            }
            if (a.createdAt > b.createdAt) {
              return -1
            }
          }
          return 0
        })
        return sessionsToShow
      }
    },
    profile () {
      return this.$store.state.profile
    }
  },
  mounted () {
    this.$bind(
      'gameBuilderActivity',
      firebaseApp.firestore()
        .collection('gameBuilderActivities')
        .doc(this.adventureId)
    )

    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name,
      adventure_id: this.$route.params.adventureId
    })

    if (
      !this.$store.state.profile.isRootAccount &&
      this.$store.state.organisation.adventureVisibilities &&
      this.$store.state.organisation.adventureVisibilities[this.$route.params.adventureId] &&
      !this.$store.state.organisation.adventureVisibilities[this.$route.params.adventureId].includes(this.$store.state.profile.id)
    ) { this.$router.push({ name: 'home' }) }
  }
}
</script>
