<template>
  <div
  @click="openExhibit()"
  class="button is-primary is-outlined"
  :class="{ 'is-loading': loading }"
  >
    <i class="fas fa-tv mr-2"></i>
    {{ buttonText }}
  </div>
</template>

<script>
export default {
  name: 'OpenSessionExhibit',
  props: {
    session: {
      type: Object,
      default: null
    },
    buttonText: {
      type: String,
      default: 'Open gallery exhibit'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    openExhibit () {
      window.open(`${this.$store.state.adminBaseUrl}/exhibit/${this.session.id}`)
    }
  }
}
</script>
