<template>
  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <div @click="downloadCsvTemplate()" class="button is-primary mr-2">
          <i class="fas fa-file-download mr-2"></i>
          Download CSV template
        </div>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <input type="file" id="csvFile" accept=".csv" style="width: 180px;"/>
        <div
          :disabled="!csvFile"
          @click="processCsv()"
          class="button is-primary mr-2"
          :class="{ 'is-loading': loading }"
        >
          <i class="fas fa-external-link-alt mr-2"></i>
          Upload
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'AccessCodeTools',
  data () {
    return {
      loading: false,
      csvFile: null
    }
  },
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  methods: {
    downloadCsvTemplate () {
      const csvTemplate = 'Code,Max Number of Devices\nABC001,5\nABC002\nDEF110,3'
      const downloadLink = document.createElement('a')
      downloadLink.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvTemplate))
      downloadLink.setAttribute('download', 'template.csv')
      document.body.appendChild(downloadLink)
      downloadLink.click()
    },
    runMethod () {
      const csvFile = document.getElementById('csvFile')
      if (csvFile.files[0]) {
        this.csvFile = csvFile.files[0]
      }
    },
    processCsv () {
      const reader = new FileReader()
      reader.onload = (e) => {
        const csvText = e.target.result
        const csvData = csvText.split('\n')
          .map(line => {
            return line.split(',')
          })
        const accessCodeData = csvData.filter((data, index) => {
          return index > 0 && data.length === 2 && data[0].length > 0
        }).map(data => {
          return {
            code: data[0],
            maxPlayers: !isNaN(parseInt(data[1])) ? parseInt(data[1]) : null
          }
        })
        this.createAccessCodes(accessCodeData)
      }
      reader.readAsText(this.csvFile)
    },
    createAccessCodes (accessCodeData) {
      this.loading = true
      this.errorMessage = null
      console.log(accessCodeData)
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'add-access-codes',
        sessionId: this.session.id,
        accessCodes: accessCodeData
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully created access codes!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
        const csvFile = document.getElementById('csvFile')
        csvFile.value = ''
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-danger',
          queue: false
        })
        this.loading = false
      })
    }
  },
  mounted () {
    const inputElement = document.getElementById('csvFile')
    inputElement.addEventListener('change', this.runMethod, false)
  }
}
</script>
