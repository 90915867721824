<template>
  <div>
    <b-table
      :checked-rows.sync="checkedRows"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      :data="filteredRanking"
    >
      <b-table-column label="Points" field="points" sortable v-slot="props">
        {{ props.row.points }}
      </b-table-column>
      <b-table-column label="Completion time (minutes)" field="points" sortable v-slot="props">
        {{ props.row.timeTakenToComplete ? (props.row.timeTakenToComplete/60).toFixed(2) : '' }}
      </b-table-column>
      <b-table-column label="Team Name" field="teamName" sortable v-slot="props">
        {{ props.row.teamName }}
      </b-table-column>
      <b-table-column label="Updated On" field="updatedOn" sortable v-slot="props">
        {{ ranking ? new Date(props.row.updatedOn).toLocaleString('en-SG') : props.row.updatedOn.toDate().toLocaleString('en-SG') }}
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'Leaderboard',
  props: {
    ranking: {
      type: Array,
      default: null
    },
    session: {
      type: Object,
      default: null
    },
    adventureId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      leaderboard: null,
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: []
    }
  },
  computed: {
    filteredRanking () {
      if (this.ranking) {
        return this.ranking
      }
      if (this.leaderboard) {
        if (this.session) {
          return this.leaderboard.ranking.filter(el => {
            return el.sessionId && el.sessionId === this.session.id
          })
        }
        return this.leaderboard.ranking.filter(el => {
          return !el.sessionId
        })
      } else {
        return []
      }
    }
  },
  methods: {
  },
  mounted () {
    if (!this.ranking) {
      const leaderboards = firebaseApp.firestore().collection('leaderboards')
      if (this.session) {
        this.$bind('leaderboard', leaderboards.doc(this.session.adventureId))
      } else if (this.adventureId) {
        this.$bind('leaderboard', leaderboards.doc(this.adventureId))
      }
    }
  }
}
</script>
