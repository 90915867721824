<template>
  <div>
    <input type="file" ref="fileInput" @change="handleCSVUpload"/>
    <ul>
      <li v-for="(data, index) in challenges" :key="index">{{ JSON.stringify(data) }}</li>
      <br>
      <br>
    </ul>
  </div>
</template>

<script>
import Papa from 'papaparse'
import { answerNewDatas } from '../utils/answerNewDatas'

export default {
  name: 'PlayTourCsvUpload',
  data () {
    return {
      csvData: [],
      challenges: [],
      chapters: []
    }
  },
  methods: {
    handleCSVUpload () {
      const csvFile = this.$refs.fileInput.files[0]
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.csvData = results.data
          this.generateObjects()
          this.generateChapters()
          this.saveToStore()
          this.$router.push({ name: 'createPlayTour' })
        }
      })
    },
    generateObjects () {
      this.csvData.forEach(row => {
        this.challenges.push({
          chapter_name: row['chapter name'],
          name: row['task name'],
          briefing: row['task briefing'],
          type: row['task type'],
          answers: this.generateAnswers(row),
          hint: !row.hint ? null : {
            reveal_after_x_tries: row['hint reveal_after_x_tries'] ? parseInt(row['hint reveal_after_x_tries']) : null,
            reveal_after_x_mins: row['hint reveal_after_x_mins'] ? parseInt(row['hint reveal_after_x_mins']) : null,
            credits: row['hint credits'] ? parseInt(row['hint credits']) : null,
            hint: row.hint
          },
          points: row['task points'] ? parseInt(row['task points']) : null,
          wrong_answer_penalty: row.wrongAnswerPenalty,
          time_penalty: row.timePenalty ? parseInt(row.timePenalty) : null,
          lose_x_points_per_min: row.loseXPointsPerMin ? parseInt(row.loseXPointsPerMin) : null,
          lose_x_points_per_x_secs: row.loseXPointsPerXSecs ? parseInt(row.loseXPointsPerXSecs) : null,
          limit_to_x_tries: row.limitToXTries ? parseInt(row.limitToXTries) : null,
          skippable: row.skippable === 'yes',
          skip_penalty: row.skip_penalty,
          skippable_after_x_tries: row.skippable_after_x_tries,
          skippable_after_x_mins: row.skippable_after_x_mins,
          item: row.item,
          item_category: row.itemCategory,
          show_specific_players_only: row.showSpecificPlayersOnly,
          custom_completion_message: row.custom_completion_message,
          custom_failure_message: row.custom_failure_message,
          max_completions_per_session: row.maxCompletionsPerSession,
          access_code: row.accessCode,
          redo_after_x_mins: row.redoAfterXMins,
          hide_answer_if_not_answered_correctly: row.hideAnswerIfNotAnsweredCorrectly,
          no_stage_points_if_not_answered_correctly: row.noStagePointsIfNotAnsweredCorrectly,
          hide_after_completion: row.hideAfterCompletion,
          time_restricted: row.timeRestricted,
          time_restricted_allow_after: row.timeRestrictedAllowAfter,
          time_restricted_allow_before: row.timeRestrictedAllowBefore,
          isPositionBased: row['location_based lat'] && row['location_based lon'],
          position: !(row['location_based lat'] && row['location_based lon']) ? null : {
            lat: parseFloat(row['location_based lat']),
            lon: parseFloat(row['location_based lon']),
            radius_in_meters: row['location_based radius'] ? parseInt(row['location_based radius']) : null,
            shownOnMap: row['location_based shown_on_map'] === 'yes',
            visibleOutsideRadius: row['location_based visible_outside_radius'] === 'yes' || row['location_based allow_completion_outside_radius'] === 'yes',
            allowCompletionOutsideRadius: row['location_based allow_completion_outside_radius'] === 'yes'
          },
          powerup_text: row.powerupText,
          powerup_stars: row.powerupStars,
          powerups: row.powerups,
          task_category: row.taskCategory
        })
      })
    },
    generateAnswers (row) {
      const answers = []
      for (const key in row) {
        const type = row['task type']
        const answerObj = answerNewDatas[type] ? JSON.parse(JSON.stringify(answerNewDatas[type])) : null
        let answer = row[key]
        if (
          key.includes('task answer') &&
          answerObj &&
          answer.length > 0
        ) {
          if (type === 'multiple-choice' && answer.charAt(0) === '*') {
            answerObj.is_valid = true
            answer = answer.substring(1)
          }
          if (type === 'number') answer = Number(answer)
          if (type === 'integer') answer = parseInt(answer)
          answerObj.value = answer
          answers.push(answerObj)
        }
      }
      return answers
    },
    generateChapters () {
      this.challenges.forEach(challenge => {
        if (
          !this.chapters.some(chapter => chapter.title === challenge.chapter_name)
        ) {
          this.chapters.push({
            title: challenge.chapter_name,
            briefing: '',
            time_limit_in_minutes: null,
            auto_forward_when_time_is_up: false,
            min_points: null,
            complete_challenges_in_order: true,
            shuffle_challenges: false,
            chapter_background_url: null,
            chapter_music_url: null,
            has_virtual_spaces: false,
            virtual_spaces: null,
            challenges: []
          })
        }

        const chapter = this.chapters.filter(chapter => chapter.title === challenge.chapter_name)[0]
        chapter.challenges.push(challenge)
      })
    },
    saveToStore () {
      const jsonObj = {
        stages: this.chapters
      }
      console.log('jsonObj', jsonObj)
      this.$store.commit('assistantJsonObj', jsonObj)
    }
  }
}
</script>
