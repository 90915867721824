<template>
  <div>
    <b-table
      v-if="completionCerts"
      :checked-rows.sync="checkedRows"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="points"
      :data="completionCerts"
    >
      <b-table-column label="Cert Number" field="certNumber" sortable v-slot="props">
        {{ props.row.certNumber }}
      </b-table-column>
      <b-table-column label="Validated?" field="validated" sortable v-slot="props">
        {{ props.row.validated ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column label="Points" field="points" v-slot="props">
        {{ props.row.totalPoints }}
      </b-table-column>
      <b-table-column label="Started" field="started" v-slot="props">
        {{ (new Date(props.row.startedAt._seconds * 1000)).toLocaleString('en-SG') }}
      </b-table-column>
      <b-table-column label="Completed" field="completed" v-slot="props">
        {{ (new Date(props.row.completedAt._seconds * 1000)).toLocaleString('en-SG') }}
      </b-table-column>
      <b-table-column label="Duration (mins)" field="points" v-slot="props">
        {{ ((props.row.completedAt._seconds - props.row.startedAt._seconds)/60).toFixed(2) }}
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'CompletionCertificates',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      completionCerts: null,
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: []
    }
  },
  methods: {
  },
  mounted () {
    if (this.session) {
      this.isLoading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-certs',
        sessionId: this.session.id
      }).then(result => {
        this.completionCerts = result.data.completionCerts
      }).catch(err => {
        console.log(err.message)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
