<template>
  <section class="section is-main-section remove-max-width show-if-mobile pb-2 pt-5 mt-4">
    <div class="container has-text-centered">
      <div class="is-size-4 has-text-weight-semibold has-text-grey">
        <i class="fas fa-desktop fa-2x mr-4"></i>
        <i class="fas fa-tablet-alt fa-2x mr-2"></i><br><br>
        The game builder is optimized designed for tablet or desktop-sized screens.<br><br>
        Please use a larger screen.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SmallScreenNotice'
}
</script>

<style>
@media screen and (min-width: 768px) {
    .show-if-mobile {
        display: none
    }
}
</style>
