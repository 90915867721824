<template>
  <div>
    <hero-bar>
      Game Assistant
    </hero-bar>
    <section class="section is-main-section">
      <div v-if="stage !== 'done'">
        <div class="is-size-3 has-text-weight-bold mb-5">
          {{ tutorialPrompts[stage].question }}
        </div>
        <div class="is-flex mb-4">
          <div
          v-for="prompt in tutorialPrompts[stage].prompts"
          :key="prompt.text" class="card assistant-card"
          @click="tutorialActions(prompt.code, prompt.configChange)"
          >
            <div v-if="prompt.image" class="card-image">
              <figure class="image">
                <img style="height: 250px;" :src="prompt.image ? prompt.image : 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/files%2Fm01oOxZoWgHY8vMUSC6H%2Fgalacticguardiansgroupshot1?alt=media&token=50ab8925-bdc7-434d-b2ae-194414e904d1'" alt="Placeholder image">
              </figure>
            </div>
            <div class="card-content">
              <div class="content is-size-5 has-text-weight-semibold">
                {{ prompt.text }}
              </div>
            </div>
          </div>
        </div>
        <button v-if="tutorialPrompts[stage].prevStage" class="button is-rounded is-primary" @click="stage = tutorialPrompts[stage].prevStage">
          <i class="fas fa-chevron-left mr-2"></i>
          back
        </button>
      </div>
      <div v-if="stage === 'done'">
        <div class="is-size-3 has-text-weight-bold mb-5">
          Before we start...
        </div>
        <div class="mb-4">
          In the next screen, the game builder will open with a template that should give you an idea of how to structure your game.
          <br><br>
          There will be <b>"===Tutorial==="</b> segments to give you more information, and you should delete them once you have read and understood, so it will not appear in the actual game.
          <div v-if="this.config.pathConfig === 'preconfigured'">
            <br>
            Also, a new tab will open with a tutorial on how to configure the different paths for different teams.
          </div>
        </div>
        <button @click="stage = prevStage" class="mt-5 button is-rounded is-primary is-light">
          <i class="fas fa-chevron-left mr-2"></i>
          Restart
        </button>
        <button class="button is-rounded is-primary mt-5 is-pulled-right" @click="start()">
          Start building
          <i class="fas fa-chevron-right ml-2"></i>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'PlayTourAssistant',
  components: {
    HeroBar
  },
  data () {
    return {
      stage: 1,
      prevStage: null,
      config: {
        pathConfig: null, // same/random/preconfigured
        isStationLocationRevealed: null,
        taskConfig: null, // one/several-all/several-some
        taskOrderedConfig: null,
        clusterConfig: null
      }
    }
  },
  computed: {
    tutorialPrompts () {
      return {
        1: {
          question: 'How do you want to create your game?',
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F7.jpg?alt=media&token=7b83d45e-1e02-40a4-b26b-48bb6a88d8e6',
              text: 'Ask me some questions about my game and create a template. Current changes in the game builder will be reset.',
              code: 2
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F2.jpg?alt=media&token=42792443-5c72-4234-adc5-004173800f9f',
              text: 'From scratch, I will figure it out on my own. I will contact customer support for help if needed.',
              code: 'scratch'
            }
          ]
        },
        2: {
          question: 'How will players move?',
          prevStage: 1,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F8.jpg?alt=media&token=a13b6464-5703-4619-a2dc-65075acc7429',
              text: 'I want players to move from a pre-configured path from one station to another station. Each station can have 1 or more tasks.',
              code: 4
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F1.jpg?alt=media&token=bd30d2c1-dcff-4fec-8162-531c57be5299',
              text: 'I want players to be able to decide which task to do first, and so on.',
              code: 3,
              configChange: {
                pathConfig: 'random'
              }
            }
          ]
        },
        4: {
          question: 'How will the paths be configured?',
          prevStage: 2,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F10.jpg?alt=media&token=54709ccd-17ec-41a7-a0c7-d68db616791d',
              text: 'I want all teams to have the same path.',
              code: 6,
              configChange: {
                pathConfig: 'same'
              }
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F12.jpg?alt=media&token=35a78aa6-bd21-4a00-a45b-37c550a48915',
              text: 'I want different teams to have different paths.',
              code: 5
            }
          ]
        },
        5: {
          question: 'How do you want to configure the paths?',
          prevStage: 4,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F6.jpg?alt=media&token=59bed652-d508-4f66-9e2b-b2853bc66987',
              text: 'I want each path to be random.',
              code: 6,
              configChange: {
                pathConfig: 'random'
              }
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F11.jpg?alt=media&token=a56e8dce-bc52-4a9d-b976-2246eb2ee15e',
              text: 'I want to configure each different path and assign it to the teams.',
              code: 6,
              configChange: {
                pathConfig: 'preconfigured'
              }
            }
          ]
        },
        6: {
          question: 'How will station locations be revealed?',
          prevStage: 4,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F9.jpg?alt=media&token=f54d8a64-9196-474f-b846-ac6c3cc0d03f',
              text: 'Players must complete a task, such as solving a puzzle, before the station’s location is revealed.',
              code: 7,
              configChange: {
                isStationLocationRevealed: false
              }
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F3.jpg?alt=media&token=01e245c3-c9d5-4fcf-9cae-dcc6d91a4c12',
              text: 'Location will be revealed at the start or after completing all the tasks in the previous station.',
              code: 7,
              configChange: {
                isStationLocationRevealed: true
              }
            }
          ]
        },
        7: {
          question: 'About the tasks in each station...',
          prevStage: 6,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F5.jpg?alt=media&token=8c6d002f-b1a7-4c54-9ccb-e86b1d7574d8',
              text: 'Each station has only 1 task.',
              code: 'done',
              configChange: {
                taskConfig: 'one'
              }
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F4.jpg?alt=media&token=fb609e59-9e82-4d16-a63c-ef7547828c77',
              text: 'Each station has 1 or more tasks.',
              code: 8
            }
          ]
        },
        8: {
          question: 'Do players have to complete every task in each station?',
          prevStage: 7,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F17.jpg?alt=media&token=f2a13f95-dafa-442c-9ac3-162c7a1d4ca0',
              text: 'Yes, they have to complete every station.',
              code: 9,
              configChange: {
                taskConfig: 'several-all'
              }
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F18.jpg?alt=media&token=f846a371-4d58-4494-aee8-3fe701eaa996',
              text: 'No, they have to complete only some as long as they collect enough points.',
              code: 'done',
              configChange: {
                taskConfig: 'several-some'
              }
            }
          ]
        },
        9: {
          question: 'Within a station, in what order will the tasks be?',
          prevStage: 8,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F14.jpg?alt=media&token=76e9c058-b3a0-446d-8e31-f4fd0b92c4e6',
              text: 'Players must complete tasks in a specific order.',
              code: 'done',
              configChange: {
                taskOrderedConfig: true
              }
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F13.jpg?alt=media&token=e1507fb5-3d25-4eb0-9bf1-ee768fbb5027',
              text: 'Players can choose which tasks to do first, and so on.',
              code: 'done',
              configChange: {
                taskOrderedConfig: false
              }
            }
          ]
        },
        3: {
          question: 'To prevent clustering, do you want the teams to start at different locations?',
          prevStage: 2,
          prompts: [
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F16.jpg?alt=media&token=39b9b5db-6e9a-4b85-85d3-f17f78d42d0d',
              text: 'Yes, I want the teams to start at different locations or stations.',
              code: 'done',
              configChange: {
                clusterConfig: true
              }
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FGame%20Assistant%2F15.jpg?alt=media&token=5831477d-5d4f-450b-a6ab-8e92324d6ecf',
              text: 'No, I will allow the teams to disperse on their own.',
              code: 'done',
              configChange: {
                clusterConfig: false
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    tutorialActions (code, configChange) {
      if (
        [8, 9].includes(code) &&
        !this.config.isStationLocationRevealed
      ) {
        this.taskOrderedConfig = false
        code = 'done'
      }

      if (code === 'done') {
        this.prevStage = this.stage
      }

      if (code === 'scratch') {
        localStorage.playtoursSkipGameAssistant = 'true'
        this.$router.push({ name: 'createPlayTour' })
      } else {
        this.stage = code
      }

      if (configChange) {
        Object.keys(this.config).forEach(key => {
          if (key in configChange) {
            this.config[key] = configChange[key]
          }
        })
      }
    },
    async start () {
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      const rawAdventureJson = await masterFunction({
        methodName: 'get-assistant-template'
      }).then(result => {
        return result.data.rawAdventureJson
      })
      const jsonObj = JSON.parse(rawAdventureJson)

      const tasksStage = { ...jsonObj.stages.pop() }

      if (
        this.config.pathConfig === 'random' &&
        this.config.clusterConfig
      ) {
        jsonObj.stages = jsonObj.stages.filter((value, i) => ![3, 4].includes(i))
        jsonObj.stages[2].challenges = tasksStage.challenges
      } else if (
        this.config.pathConfig === 'random' &&
        !this.config.clusterConfig
      ) {
        jsonObj.stages = jsonObj.stages.filter((value, i) => ![1, 3, 4].includes(i))
        jsonObj.stages[1].challenges = tasksStage.challenges
      } else {
        jsonObj.stages = jsonObj.stages.filter((value, i) => ![1, 2].includes(i))
      }

      if (this.config.pathConfig !== 'random') {
        if (!this.config.isStationLocationRevealed) {
          jsonObj.stages.splice(2, 1)
          if (this.config.taskConfig === 'one') {
            jsonObj.stages[1].challenges.splice(3, jsonObj.stages[1].challenges.length - 3)
            jsonObj.stages[1].minPoints = jsonObj.stages[1].challenges.reduce((total, challenge) => {
              return total + (challenge.points ? challenge.points : 0)
            }, 0)
          }
        } else {
          jsonObj.stages.splice(1, 1)
          if (this.config.taskConfig === 'one') {
            jsonObj.stages[1].challenges.splice(1, jsonObj.stages[1].challenges.length - 1)
            jsonObj.stages[1].minPoints = jsonObj.stages[1].challenges.reduce((total, challenge) => {
              return total + (challenge.points ? challenge.points : 0)
            }, 0)
          }
        }

        if (this.config.taskConfig === 'several-some') {
          jsonObj.stages[1].minPoints -= jsonObj.stages[1].challenges[2].points
        }

        if (this.taskOrderedConfig) {
          jsonObj.stages[1].completeChallengesInOrder = true
        }

        jsonObj.stages.splice(1, 0, { ...jsonObj.stages[1] })
        jsonObj.stages[1].title = 'Station 1'
        jsonObj.stages[2].title = 'Station 2'

        jsonObj.stages.splice(jsonObj.stages.length - 1, 0, tasksStage)
      } else if (this.config.pathConfig === 'random') {
        jsonObj.shuffleChapters = true
        jsonObj.shuffleChaptersNotFirstLast = true
      }

      if (this.config.pathConfig === 'preconfigured') {
        window.open('https://www.playtours.app/post/how-to-set-different-chapter-flows-for-different-teams')
      }

      this.$store.commit('assistantJsonObj', jsonObj)
      this.$router.push({ name: 'createPlayTour' })
    }
  }
}
</script>

<style>
.assistant-card {
  margin-right: 10px;
  cursor: pointer;
  max-width: 50%;
  margin-bottom: 0 !important;
  /* height: 330px; */
}
</style>
