<template>
  <card-component title="Session Details" icon="fa-calendar-day">
    <form v-if="form" @submit.prevent="() => {}">
      <b-field horizontal label="Name" message="Mandatory. Can be changed.">
        <b-input v-model="form.name" name="name" required />
      </b-field>

      <!-- <b-field horizontal label="Player's game link" message="Link to share with players for this session.">
        <b-input :value="link" name="link" readonly />
        <div>
          <div @click="copyLink(link)" class="button is-primary is-light mr-1">
            <i class="fas fa-copy mr-2"></i>
            Copy link
          </div>
          <div @click="newTab(link)" class="button is-primary is-light">
            <i class="fas fa-external-link-alt mr-2"></i>
            Open
          </div>
        </div>
      </b-field> -->
      <!-- <div class="has-text-centered">
        <div style="margin: auto;" class="has-text-danger has-text-weight-bold mb-2">
          <a href="https://www.playtours.app/post/a-guide-to-starting-a-playtours-enabled-event" target="_blank">Click here</a> to read an important notice about giving players QR codes.
        </div>
      </div> -->
      <!-- <b-field horizontal label="Player's game QR code" message="Players can scan this to create teams for this session. Right-click and 'Save image as...'">
        <vue-qrcode :value="qrLink" />
      </b-field> -->
      <!-- <b-field horizontal label="Facilitator's link" message="Link to share with your facilitators for this session.">
        <b-input :value="faciLink" name="faciLink" readonly />
        <div>
          <div @click="copyLink(faciLink)" class="button is-primary is-light mr-1">
            <i class="fas fa-copy mr-2"></i>
            Copy Link
          </div>
          <div @click="newTab(faciLink)" class="button is-primary is-light">
            <i class="fas fa-external-link-alt mr-2"></i>
            Open
          </div>
        </div>
      </b-field> -->
      <!-- <b-field horizontal label="Facilitator's QR Code" message="Facilitators can scan this to access the facilitation dashboard. Right-click and 'Save image as...'">
        <vue-qrcode :value="faciLink" />
      </b-field> -->
      <b-field horizontal label="Reset facilitator's link" message="If the secret link is compromised, you can reset it.">
        <button
          @click="resetFaciLink"
          class="button is-primary is-light mr-2"
          :class="{ 'is-loading': loading }"
        >
          Reset facilitator's link
        </button>
      </b-field>

      <b-field label="Is active" horizontal>
        <b-switch v-model="form.isActive">
        </b-switch>
      </b-field>
      <b-field v-if="!form.isActive" horizontal label="Inactive notice" message="Shown above the Play Now button. Useful to let players know why it is inactive and when it will be made active.">
        <b-input v-model="form.isActiveNotice" name="isActiveNotice" type="textarea"/>
      </b-field>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Chat
      </div>
      <div class="box">
        <article v-if="!form.allowFaciMessaging" class="message is-warning">
          <div class="message-body py-2 is-size-7">
            To allow players to message facilitators during a game, activate the option below.
            Facilitators have to access the dashboard via the Facilitator's Link to see and reply to messages.
          </div>
        </article>
        <b-field label="Allow team-to-faci messaging" message="Allow teams to send messages to faci. Faci can see and reply messages via the Facilitator's Link." horizontal>
          <b-switch v-model="form.allowFaciMessaging">
          </b-switch>
        </b-field>
        <b-field label="Allow teams to message other teams in the session" message="Allow teams to send session-wide messages that can be seen by all other teams and facis." horizontal>
          <b-switch v-model="form.allowSessionChat">
          </b-switch>
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Location
      </div>
      <div class="box">
        <b-field label="Require teams' position" message="Allow facilitators to track players' location as they play." horizontal>
          <b-switch v-model="form.requireTeamPosition">
          </b-switch>
        </b-field>
        <b-field v-if="form.requireTeamPosition" label="Allow teams to view other teams' locations" horizontal>
          <b-switch v-model="form.allowTeamPositionsView">
          </b-switch>
        </b-field>
        <b-field label="Allow location picker if GPS access is blocked" message="Allow players to manually pick their location if GPS access is blocked on their device. Strongly recommended for public games that are non-competitive." horizontal>
          <b-switch v-model="form.allowLenientLocationPicker">
          </b-switch>
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Leaderboard
      </div>
      <div class="box">
        <b-field label="Hide leaderboard" horizontal>
          <b-switch v-model="form.hideLeaderboard">
          </b-switch>
        </b-field>
        <b-field v-if="!form.hideLeaderboard" label="Use friendly leaderboard" message="A 'friendly' leaderboard hides the points of other teams in front of the viewing team, and hides the positions of the bottom 3 or 30% of the total number of team, whichever is higher." horizontal>
          <b-switch v-model="form.hasFriendlyLeaderboard">
          </b-switch>
        </b-field>
        <b-field v-if="!form.hideLeaderboard" label="Show only upon completion" message="Leaderboard will only be revealed after the team has completed the game." horizontal>
          <b-switch v-model="form.showLeaderboardAtCompletion">
          </b-switch>
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Player & Team Limits
      </div>
      <div class="box">
        <article class="message is-warning">
          <div class="message-body py-2 is-size-7">
          For games where players play individually and cannot form teams, set the max no. of devices in a team to 1.
          They will only be asked for their names at the start of the game.
          </div>
        </article>
        <b-field horizontal label="Max no. of devices in a team" message="Limit the total number of devices allowed in a team. If a new device joins a full team, the device that has been on the game the longest will be removed.">
          <b-input v-model="form.maxPlayersPerTeam" name="maxTeams" type="number" />
        </b-field>
        <b-field horizontal label="Max no. of teams" message="Limit the total number of teams allowed.">
          <b-input v-model="form.maxTeams" name="maxTeams" type="number" />
        </b-field>
        <b-field horizontal label="Max no. of devices" message="Limit the total number of devices allowed.">
          <b-input v-model="form.maxPlayers" name="maxPlayers" type="number" />
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Miscellaneous
      </div>
      <div class="box">
        <!-- <b-field label="Has completion certificates" message="Completion certificates allow you to validate a team's completion to award prizes, etc. Cannot be disabled once enabled for a specific session." horizontal>
          <b-switch v-model="form.hasCompletionCert">
          </b-switch>
        </b-field> -->
        <b-field v-if="form.hasCompletionCert" horizontal label="Additional certificate instructions" message="Shown on the debrief page after players complete the game.">
          <b-input v-model="form.completionCertBriefing" name="maxPlayers" type="textarea"/>
        </b-field>
        <b-field horizontal label="Emails to notify for new judgements" message="Comma-separated, e.g. tom@abc.com, susan@abc.com">
          <b-input v-model="form.notifyEmails" name="notifyEmails" />
        </b-field>
        <b-field label="Auto-approve tasks requiring judgement" message="Auto-approve tasks that has the 'judged-image', 'judged-video' & 'judged-text' type." horizontal>
          <b-switch v-model="form.autoApproveJudgedTasks">
          </b-switch>
        </b-field>
        <b-field label="Allow removal of time limits (for mobility-impeded players)" message="Allow teams to remove time limits, for use if they have mobility-impeded players." horizontal>
          <b-switch v-model="form.allowRemoveTimeLimits">
          </b-switch>
        </b-field>
        <!-- <b-field label="Send team link email to players" message="Players can optionally provide their email when creating teams to receive a return link if access is lost." horizontal>
          <b-switch v-model="form.sendTeamLinkEmail">
          </b-switch>
        </b-field> -->
        <!-- <b-field label="Send team link email to players" message="Players can optionally provide their email when creating teams to receive a return link if access is lost." horizontal>
          <b-switch v-model="form.sendTeamLinkEmail">
          </b-switch>
        </b-field> -->
        <b-field
          label="Send team link email to players"
          message="Players can provide their email when creating teams to receive a return link if access is lost."
          horizontal>
            <b-select
              v-model="form.sendTeamLinkEmail"
              placeholder="Select an option"
              required
            >
              <option
                v-for="(x, index) in ['Yes, optional', 'Yes, mandatory', 'No']"
                :key="index"
                :value="x"
              >
                {{ x }}
              </option>
            </b-select>
          </b-field>
      </div>

      <div v-if="organisation.bookeoApiKey">
        <div class="is-size-5 has-text-weight-semibold mb-0">
          Bookeo Integration
        </div>
        <div class="box">
          <b-field horizontal label="Product Code" message="Mandatory, if there is an integration.">
            <b-input v-model="form.bookeoProductId" name="productId" />
          </b-field>

          <div class="has-text-centered">
            <div style="margin: auto;" class="mb-2">
              You can use these:
              <span v-pre>{{ customer_name }}</span>&nbsp;
              <span v-pre>{{ customer_first_name }}</span>&nbsp;
              <span v-pre>{{ customer_last_name }}</span>&nbsp;
              <span v-pre>{{ event_name }}</span>&nbsp;
              <span v-pre>{{ booking_number }}</span>&nbsp;
              <span v-pre>{{ team_link }}</span>&nbsp;
              The values are obtained directly from Bookeo.
            </div>
          </div>

          <b-field horizontal label="Email Subject" message="Mandatory, if there is an integration.">
            <b-input v-model="form.bookeoEmailSubject" name="bookeoEmailSubject" :placeholder="bookeoEmailSubjectPlaceholder"/>
          </b-field>

          <b-field horizontal label="Email Template" message="">
            <b-input
              v-model="form.bookeoEmailTemplate"
              :placeholder="bookeoEmailPlaceholder"
              name="emailTemplate"
              type="textarea"
            />
          </b-field>

          <b-field horizontal label="Error Email Address" message="Mandatory, if there is an integration. If there is an error when sending to the customer's email, we will notify using this email. Make sure that this is a valid email.">
            <b-input v-model="form.bookeoErrorEmail" name="productId" />
          </b-field>
        </div>
      </div>

      <hr />
      <div class="is-flex is-flex-direction-row-reverse">
        <div v-if="errorMessage" class="container">
          <div class="is-size-6 has-text-danger">
            {{ errorMessage }}
          </div>
        </div>
        <button
          @click="editSession"
          class="button is-primary is-medium mr-2"
          :class="{ 'is-loading': loading }"
          :disabled="!allowEditSession"
        >
          Save changes
        </button>
      </div>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'
// import VueQrcode from 'vue-qrcode'

export default {
  name: 'EditSessionForm',
  components: {
    CardComponent
    // VueQrcode
  },
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      editedSession: null,
      allowEditSession: false,
      errorMessage: null,
      loading: false,
      bookeoEmailSubjectPlaceholder: 'Time to play, {{ customer_name }}!',
      bookeoEmailPlaceholder: 'Dear {{ customer_name }},\n\nThank you for your purchase of {{ event_name }}, with booking number {{ booking_number }}! We hope that you will have an amazing time.\n\nWhen you are at the starting location, click on this link to begin: {{ team_link }}.\n\nThis link is only for your team, keep it a secret.\n\nBest regards,\nAlbert Eintstein',
      form: {
        name: null,
        tag: null,
        hideLeaderboard: false,
        hasFriendlyLeaderboard: false,
        showLeaderboardAtCompletion: false,
        allowSessionChat: false,
        allowFaciMessaging: null,
        requireTeamPosition: null,
        allowTeamPositionsView: false,
        allowLenientLocationPicker: false,
        maxTeams: null,
        maxPlayersPerTeam: null,
        maxPlayers: null,
        notifyEmails: null,
        autoApproveJudgedTasks: false,
        allowRemoveTimeLimits: false,
        sendTeamLinkEmail: true,
        isActive: null,
        isActiveNotice: null,
        bookeoProductId: null,
        bookeoEmailSubject: null,
        bookeoEmailTemplate: null,
        bookeoErrorEmail: null
      }
    }
  },
  methods: {
    newTab (url) {
      window.open(url)
    },
    editSession () {
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'edit-session',
        sessionId: this.session.id,
        ...this.form
      }).then(result => {
        this.$store.commit('savedPlayTours', true)
        this.$buefy.toast.open({
          message: 'Successfully edited session!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    },
    async copyLink (link) {
      await navigator.clipboard.writeText(link)
      this.$buefy.toast.open({
        message: 'Link copied! Paste (ctrl-v) it anywhere.',
        type: 'is-primary',
        queue: false
      })
    },
    resetFaciLink () {
      const confirmed = confirm('Current link will be replaced by a new link, confirm reset?')
      if (!confirmed) { return }
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'reset-faci-key',
        sessionId: this.session.id
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully resetted facilitator link!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  },
  watch: {
    form: {
      handler (newValue, oldValue) {
        if (oldValue && oldValue.name) {
          this.$store.commit('savedPlayTours', false)
        }
      },
      deep: true
    },
    'form.name': function (newVal) {
      if (
        newVal && newVal.length > 0
      ) {
        this.allowEditSession = true
      } else {
        this.allowEditSession = false
      }
    },
    'form.requireTeamPosition': function (newVal, oldVal) {
      if (oldVal && !newVal) {
        this.form.allowTeamPositionsView = false
      }
    },
    'form.allowTeamPositionsView': function (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.form.requireTeamPosition = true
      }
    },
    'form.hideLeaderboard': function (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.form.hasFriendlyLeaderboard = false
      }
    },
    'form.sendTeamLinkEmail': function (newVal) {
      if (newVal === false || newVal === null) this.form.sendTeamLinkEmail = 'No'
      else if (newVal === true) this.form.sendTeamLinkEmail = 'Yes, optional'
    }
  },
  computed: {
    link () {
      return `${this.$store.state.baseUrl}/p/${this.session.id}`
    },
    qrLink () {
      return `${this.$store.state.baseUrl}/scan/join/${this.session.adventureId}/${this.session.id}`
    },
    faciLink () {
      return `https://admin.playtours.app/faci/${this.session.facilitatorKey}`
    },
    organisation () {
      return this.$store.state.organisation
    }
  },
  mounted () {
    this.form = { ...this.session }
    if (this.session.sendTeamLinkEmail === true) {
      this.form.sendTeamLinkEmail = 'Yes, optional'
    } else if (!this.session.sendTeamLinkEmail) {
      this.form.sendTeamLinkEmail = 'No'
    }
    this.$store.commit('savedPlayTours', true)
  }
}
</script>
