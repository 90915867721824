<template>
  <div @click="confirmAndTriggerBookeoEmail" class="button is-primary is-light is-small is-clickable">
    <i class="fas fa-envelope mr-2"></i>
    Trigger email
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'TriggerBookeoEmailButton',
  props: {
    teamCode: {
      type: String,
      default: null
    }
  },
  methods: {
    confirmAndTriggerBookeoEmail () {
      if (confirm('Triggering email... are you sure?')) {
        this.triggerBookeoEmail()
      }
    },
    triggerBookeoEmail () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'trigger-bookeo-email',
        teamCode: this.teamCode
      }).then(result => {
        alert('Email triggered with specified email subject and template as configured in this session.')
      }).catch(error => {
        console.error('Error triggering email', error)
      })
    }
  }
}
</script>
