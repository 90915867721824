<template>
  <div>
    <hero-bar>
      Add
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <add-admin-form class="tile is-child" />
      </tiles>
    </section>
  </div>
</template>

<script>
import AddAdminForm from '@/components/AddAdminForm'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'

export default {
  name: 'AdminAdd',
  components: {
    HeroBar,
    // ClientsTableSample,
    AddAdminForm,
    Tiles
    // Notification
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
