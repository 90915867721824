<template>
  <div class="is-flex is-justify-content-flex-end mb-1">
    <div @click="downloadCsv()" class="button is-primary" :class="{ 'is-loading': loading }">
      <i class="fas fa-stream mr-2"></i>
      Download leaderboard
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'DownloadLeaderboard',
  props: {
    ranking: {
      type: Array,
      default: null
    },
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      leaderboard: null,
      loading: false
    }
  },
  computed: {
    filteredRanking () {
      if (this.ranking) {
        return this.ranking
      }
      if (this.leaderboard) {
        if (this.session) {
          return this.leaderboard.ranking.filter(el => {
            return el.sessionId && el.sessionId === this.session.id
          })
        }
        return this.leaderboard.ranking.filter(el => {
          return !el.sessionId
        })
      } else {
        return []
      }
    }
  },
  methods: {
    downloadCsv () {
      const filename = `playtours_leaderboard_${new Date().toISOString()}.csv`
      const headers = [['Team Name', 'Points', 'Completion time (minutes)']]
      const data = this.filteredRanking.map(rank => {
        return [
          rank.teamName,
          rank.points,
          rank.timeTakenToComplete ? (rank.timeTakenToComplete / 60).toFixed(3) : ''
        ]
      })
      this.exportToCsv(filename, headers.concat(data))
    },
    exportToCsv (filename, rows) {
      var processRow = function (row) {
        var finalVal = ''
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? '' : row[j].toString()
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString()
          };
          var result = innerValue.replace(/"/g, '""')
          if (result.search(/("|,|\n)/g) >= 0) { result = '"' + result + '"' }
          if (j > 0) { finalVal += ',' }
          finalVal += result
        }
        return finalVal + '\n'
      }

      var csvFile = ''
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
      }

      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
  },
  mounted () {
    if (!this.ranking && this.session) {
      const leaderboards = firebaseApp.firestore().collection('leaderboards')
      if (this.session) {
        this.$bind('leaderboard', leaderboards.doc(this.session.adventureId))
      }
    }
  }
}
</script>
