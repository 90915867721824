<template>
  <div
  @click="deleteSessionMedia()"
  class="button is-danger"
  :class="{ 'is-loading': loading }"
  >
    <i class="fas fa-trash mr-2"></i>
    Delete {{ session.galleryPhotos && session.galleryPhotos.length > 1 ? 'all' : '' }} {{ session.galleryPhotos ? session.galleryPhotos.length : '' }} media
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'DeleteSessionMedia',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    deleteSessionMedia () {
      let confirmed = confirm('Confirm deletion of ALL media? Deleted media cannot be restored.')
      if (!confirmed) { return }
      confirmed = confirm('Click "OK" again to confirm deletion. Deleted media cannot be restored.')
      if (!confirmed) { return }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'delete-session-media',
        sessionId: this.session.id
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Successfully added!',
          type: 'is-primary',
          queue: false
        })
        this.name = null
        this.loading = false
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      })
    }
  },
  computed: {
    canSubmit () {
      return (
        this.name &&
        this.name.length > 0 &&
        !this.session.teams.some(team => {
          return team.name === this.name
        })
      )
    }
  }
}
</script>
