<template>
  <div class="modal" :class="{'is-active': challenge}">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Copy tasks from another game</p>
        <button class="delete" aria-label="close" @click="$emit('close')"></button>
      </header>
      <section class="modal-card-body">
        <div v-if="challenges && challenges.length > 0">
          <div v-if="challenges && challenges.length > 0">
            <div v-for="(group, adventureName) in groupedChallenges" :key="adventureName" class="mb-2">
              <h2 class="is-size-5 has-text-weight-semibold mb-2">{{ adventureName }}</h2>   <!-- This is the heading for each group -->
              <div
                v-for="(challenge, index) in group"
                :key="index"
                class="button is-primary is-small is-rounded is-outlined mr-2 mb-1"
                @click="setChallenge(challenge)"
              >
              {{ challenge.stageTitle }}: {{ challenge.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="challenges && challenges.length == 0">
          No challenges to copy.
        </div>
        <div v-if="!challenges">Loading...</div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="$emit('close')">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'CopyChallenges',
  props: {
    challenge: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      stages: null,
      challenges: null
    }
  },
  computed: {
    groupedChallenges () {
      return this.challenges.reduce((groups, challenge) => {
        const key = challenge.adventureName
        if (!groups[key]) {
          groups[key] = []
        }
        groups[key].push(challenge)
        return groups
      }, {})
    }
  },
  methods: {
    setChallenge (challenge) {
      if (!confirm('Confirm?')) return

      const convertToSnakeCase = (str) => {
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
      }

      const convertToCamelCase = (str) => {
        return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
      }

      const duplicateKeys = (obj) => {
        const newObj = {}
        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key)) {
            const snakeCaseKey = convertToSnakeCase(key)
            const camelCaseKey = convertToCamelCase(key)
            newObj[key] = obj[key]
            if (key !== snakeCaseKey) {
              newObj[snakeCaseKey] = obj[key]
            }
            if (key !== camelCaseKey) {
              newObj[camelCaseKey] = obj[key]
            }
          }
        }
        return newObj
      }

      const duplicatedChallenge = duplicateKeys(challenge)
      this.$emit('setChallenge', JSON.parse(JSON.stringify(duplicatedChallenge)))
      this.$emit('close')
    },
    getChallenges () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-all-challenges'
      }).then(result => {
        this.stages = result.data.stages
        this.challenges = result.data.challenges
      })
    }
  },
  mounted () {
    this.getChallenges()
  }
}
</script>
