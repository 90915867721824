<template>
  <div
  @click="deleteSession()"
  class="button is-danger is-light ml-2"
  :class="{ 'is-loading': loading }"
  >
    <i class="fas fa-trash mr-2"></i>
    Delete
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'SessionDeleteButton',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    deleteSession () {
      let confirmed = confirm('Deleting session will delete all teams, players, the leaderboard and delete all uploaded media in gallery. Are you sure you want to delete?')
      if (!confirmed) { return }
      confirmed = confirm('Click OK once again to delete session.')
      if (!confirmed) { return }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'reset-session',
        sessionId: this.session.id,
        isDelete: true
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Successfully deleted!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
        this.$router.push({ name: 'managePlayTour', params: { adventureId: this.session.adventureId } })
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      })
    }
  }
}
</script>
