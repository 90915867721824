<template>
  <div
  @click="resetSession()"
  class="button is-light ml-2"
  :class="{ 'is-loading': loading }"
  >
    <i class="fas fa-history mr-2"></i>
    Reset
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'ResetSessionButton',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    resetSession () {
      let confirmed = confirm('Resetting session will delete all teams, players, clear the leaderboard and delete all uploaded media in gallery. Alternatively, you can create a new session instead of resetting. Are you sure you want to reset?')
      if (!confirmed) { return }
      confirmed = confirm('Click OK once again to reset session.')
      if (!confirmed) { return }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'reset-session',
        sessionId: this.session.id
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Successfully resetted!',
          type: 'is-primary',
          queue: false
        })
        this.name = null
        this.loading = false
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      })
    }
  }
}
</script>
