<template>
  <div>
    <hero-bar>
      <!-- <router-link v-if="adventureId" :to="{name:'managePlayTour', params: { adventureId }}" class="button is-primary is-light">
        <i class="fas fa-chevron-left mr-2"></i>
        Manage game
      </router-link> -->
      <div v-if="!adventureName">
        Game Builder
      </div>
      <div v-else>
        <div class="is-size-7">
          Game Builder
        </div>
        <div>
          {{ adventureName }}
        </div>
      </div>
      <!-- <FaqAiChat slot="right"/> -->
    </hero-bar>
    <small-screen-notice />
    <section class="section is-main-section remove-max-width pt-3">
      <tiles>
        <create-play-tour-form
        :adventureId="adventureId"
        class="tile is-child"
        v-on:adventureName="setAdventureName"
        />
      </tiles>
    </section>
  </div>
</template>

<script>
import CreatePlayTourForm from '@/components/CreatePlayTourForm'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import SmallScreenNotice from '../components/SmallScreenNotice.vue'
// import FaqAiChat from '../components/FaqAiChat.vue'

export default {
  name: 'PlayTourEdit',
  components: {
    HeroBar,
    // ClientsTableSample,
    CreatePlayTourForm,
    Tiles,
    SmallScreenNotice
    // FaqAiChat
  },
  data () {
    return {
      adventureName: null,
      adventureId: this.$route.params.adventureId
    }
  },
  methods: {
    setAdventureName (adventureName) {
      this.adventureName = adventureName
    }
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name,
      adventure_id: this.$route.params.adventureId
    })
  }
}
</script>
