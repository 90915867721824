<template>
  <div>
    <hero-bar>
      Games
      <create-game-button slot="right"/>
    </hero-bar>
    <section class="section is-main-section pb-1">
      <contact-solutions-team />
    </section>
    <section class="section is-main-section">
      <div class="is-flex is-justify-content-flex-end mb-1">
        <b-input v-model="filterText" placeholder="Search games..." />
      </div>
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Games"
        icon="fa-running"
      >
        <adventures-table
          :checkable="false"
          :filterText="filterText"
        />
      </card-component>

    </section>
  </div>
</template>

<script>
import AdventuresTable from '@/components/AdventuresTable'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import ContactSolutionsTeam from '@/components/ContactSolutionsTeam'
import CreateGameButton from '../components/CreateGameButton.vue'

export default {
  name: 'Tables',
  components: {
    HeroBar,
    CardComponent,
    ContactSolutionsTeam,
    AdventuresTable,
    CreateGameButton
  },
  data () {
    return {
      filterText: ''
    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'PlayTours']
    }
  },
  mounted () {
    const scr = document.createElement('script')
    scr.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-10814770953/pRlICN_uqqADEIme8aQo'})"
    document.body.appendChild(scr)

    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
