<template>
  <div class="my-1">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">X-Y Coordinates</label>
      </div>
      <div class="field-body field-body-modifier">
        <div class="field-body">
          <div class="button is-dark mr-2" @click="toggleShowSelector">
            <i class="fas fa-globe mr-2"></i>
            Open selector
          </div>
          <div class="field" @click="toggleShowSelector">
            <p class="control is-expanded">
              <input class="input" type="number" placeholder="X" :value="x">
            </p>
          </div>
          <div class="field" @click="toggleShowSelector">
            <p class="control is-expanded">
              <input class="input" type="number" placeholder="Y" :value="y">
            </p>
          </div>
        </div>
        <p class="help has-text-grey-light mt-0">
          {{ helptext }}
        </p>
      </div>
    </div>
    <div v-if="showSelector" class="modal" :class="{'is-active': showSelector}">
      <div class="modal-background is-clickable" @click="toggleShowSelector"></div>
      <div class="modal-card map-modal-modifier" style="height: 100%">
        <header class="modal-card-head">
          <p class="modal-card-title">Select X-Y Coordinates</p>
          <button class="delete is-clickable" aria-label="close" @click="toggleShowSelector"></button>
        </header>
        <section class="modal-card-body">
          <v-pannellum
          v-show="panellumData"
          :src="panellumData"
          style="height: 80%;"
          :pitch.sync="pitch"
          :yaw.sync="yaw"
          >
          </v-pannellum>
          <div class="field is-horizontal mt-2">
            <div class="field-label is-normal">
              <label class="label">Coordinates</label>
            </div>
            <div class="field-body field-body-modifier">
              <div class="field-body">
                <div class="field">
                  <p class="control is-expanded">
                    <input class="input" type="number" placeholder="X" :value="newX" disabled>
                  </p>
                </div>
                <div class="field">
                  <p class="control is-expanded">
                    <input class="input" type="number" placeholder="Y" :value="newY" disabled>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
          class="button is-fullwidth is-warning"
          @click="changeXY"
          v-if="newX !== x || newY !== y"
          >
            Set
          </div>
          <div
          class="button is-fullwidth is-primary"
          @click="toggleShowSelector"
          v-if="newX === x && newY === y"
          >
            Confirm
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanellumXYInput',
  props: ['imageUrl', 'x', 'y', 'helptext'],
  data () {
    return {
      showSelector: false,
      pitchEvent: 'update:pitch',
      pitch: null,
      yaw: null,
      newX: null,
      newY: null
    }
  },
  methods: {
    toggleShowSelector () {
      this.showSelector = !this.showSelector
      if (
        !this.localX || !this.localY
      ) {
        this.localX = 0
        this.localY = 0
      }
    },
    changeXY () {
      this.$emit('updateXY', {
        x: parseFloat(this.newX.toFixed(6)),
        y: parseFloat(this.newY.toFixed(6))
      })
    }
  },
  mounted () {
    this.newX = this.x
    this.newY = this.y
  },
  computed: {
    localX: {
      get () {
        return this.x
      },
      set (value) {
        this.$emit('updateX', value)
      }
    },
    localY: {
      get () {
        return this.y
      },
      set (value) {
        this.$emit('updateY', value)
      }
    },
    panellumData () {
      const scenes = {
        default: {
          title: 'default',
          hfov: 110,
          pitch: 0,
          yaw: 0,
          panorama: this.imageUrl,
          hotSpots: [{
            pitch: this.localY,
            yaw: this.localX,
            type: 'scene',
            text: 'Change X-Y by moving around'
          }]
        }
      }
      return {
        default: {
          firstScene: 'default'
        },
        scenes
      }
    }
  },
  watch: {
    yaw (newYaw) {
      this.newX = parseFloat(newYaw.toFixed(6))
    },
    pitch (newPitch) {
      this.newY = parseFloat(newPitch.toFixed(6))
    }
  }
}
</script>

<style>
.line{
  width: 112px;
  height: 47px;
  border-bottom: 1px solid black;
  position: absolute;
}
</style>
