<template>
  <card-component title="Add Admin" icon="fa-user-plus">
    <form v-if="form" @submit.prevent="() => {}">
      <!-- <b-field horizontal label="Avatar">
        <file-picker />
      </b-field>
      <hr /> -->
      <b-field horizontal label="Name" message="Can be changed">
        <b-input v-model="form.name" name="name" required />
      </b-field>
      <b-field horizontal label="E-mail" message="Cannot be changed. Must not be registered in PlayTours.">
        <b-input v-model="form.email" name="email" type="email" required />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <!-- <article class="message">
            <div class="message-body py-2">
              An email will be sent for the admin to set their own password. Admins can be deleted at any point in time by the root account (you).
            </div>
          </article> -->
          <div v-if="errorMessage" class="container mb-2">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <div v-if="successMessage" class="container mb-2">
            <div class="is-size-6 has-text-success">
              {{ successMessage }}
            </div>
          </div>
          <button
            @click="addAdmin"
            class="button is-primary"
            :class="{ 'is-loading': loading }"
          >
            Add Admin
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'AddAdminForm',
  components: {
    CardComponent
  },
  data () {
    return {
      allowEditProfile: false,
      errorMessage: null,
      successMessage: null,
      loading: false,
      form: {
        name: null,
        email: null
      }
    }
  },
  methods: {
    addAdmin () {
      this.loading = true
      this.errorMessage = null
      this.successMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'add-member',
        name: this.form.name,
        email: this.form.email
      }).then(result => {
        this.successMessage = `Successfully added ${this.form.name}! The password is: "${result.data.password}". Please inform the admin to change their password once they have logged in. This password is not saved anywhere else and will disappear once you leave this page.`
        this.loading = false
        this.form = {
          name: null,
          email: null
        }
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  }
}
</script>
