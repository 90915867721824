<template>
  <div>
    <b-table
      v-if="accessCodes"
      :checked-rows.sync="checkedRows"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="points"
      :data="accessCodes"
    >
      <b-table-column label="Code" field="code" sortable v-slot="props">
        {{ props.row.code }}
      </b-table-column>
      <b-table-column label="Max no of devices" field="maxPlayers" v-slot="props">
        {{ props.row.maxPlayers }}
      </b-table-column>
      <b-table-column label="Created on" field="createdAt" v-slot="props">
        {{ (new Date(props.row.createdAt.seconds * 1000)).toLocaleString('en-SG') }}
      </b-table-column>
      <b-table-column label="Used on" field="usedAt" v-slot="props">
        {{ props.row.usedAt ? (new Date(props.row.usedAt.seconds * 1000)).toLocaleString('en-SG') : '' }}
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'AccessCodesTable',
  props: {
    accessCodes: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: []
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>
