<template>
  <div class="is-flex is-justify-content-flex-end mb-1">
    <b-input v-model="name" placeholder="Type team name" />
    <div
    @click="addPreCreatedTeam()"
    class="button is-primary ml-2"
    :class="{ 'is-loading': loading }"
    :disabled="!canSubmit"
    >
      <i class="fas fa-users mr-2"></i>
      Add Pre-created Team
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'AddPreCreatedTeam',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      name: null,
      loading: false
    }
  },
  methods: {
    addPreCreatedTeam () {
      if (!this.canSubmit) {
        return
      }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'add-pre-created-teams',
        sessionId: this.session.id,
        names: this.name.split(';')
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Successfully added!',
          type: 'is-primary',
          queue: false
        })
        this.name = null
        this.loading = false
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      })
    }
  },
  computed: {
    canSubmit () {
      return (
        this.name &&
        this.name.length > 0 &&
        !this.session.teams.some(team => {
          return team.name === this.name
        })
      )
    }
  }
}
</script>
