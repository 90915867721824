<template>
  <div @click="downloadQr" class="button is-primary is-light is-small is-clickable">
    <i class="fas fa-qrcode mr-2"></i>
    Download QR
    <vue-qrcode :id="`team-qr-code-${teamCode}`" v-show="false" :value="teamLink" @change="onDataUrlChange" />
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: 'TeamDownloadQrButton',
  components: {
    VueQrcode
  },
  data () {
    return {
      dataUrl: null
    }
  },
  props: {
    teamCode: {
      type: String,
      default: null
    },
    teamName: {
      type: String,
      default: null
    }
  },
  computed: {
    teamLink () {
      return `https://pyts.link/lobby/${this.teamCode}`
    }
  },
  methods: {
    onDataUrlChange (dataUrl) {
      this.dataUrl = dataUrl
      console.log('dataUrl', dataUrl)
    },
    downloadQr () {
      const el = document.querySelector(`#team-qr-code-${this.teamCode}`)
      var downloadLink = document.createElement('a')
      downloadLink.href = el.src
      downloadLink.download = `${this.teamName} - ${this.teamCode}`
      downloadLink.click()
    }
  }
}
</script>
