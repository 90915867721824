<template>
  <div class="is-flex is-justify-content-flex-end mb-1">
    <div @click="openCertValidator()" class="button is-primary">
      <i class="fas fa-external-link-alt mr-2"></i>
      Open Certificate Validator
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompletionCertsTools',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  methods: {
    openCertValidator () {
      window.open(`https://admin.playtours.app/cert-validator/${this.session.certValidatorKey}`)
    }
  }
}
</script>
