<template>
  <div>
    <modal-box
      :is-active="isModalActive"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      :data="sessions"
    >
      <b-table-column label="Name" field="name" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <!-- <b-table-column label="Tag" field="tag" sortable v-slot="props">
        {{ props.row.tag }}
      </b-table-column> -->
      <b-table-column label="Created" field="createdAt" v-slot="props">
        {{ props.row.createdAt ? new Date(props.row.createdAt.toDate()).toLocaleString('en-SG', { dateStyle: 'medium' }) : '' }}
      </b-table-column>
      <b-table-column label="Active" field="isActive" v-slot="props">
        {{ props.row.isActive ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-right">
          <!-- <div @click="goToGameLink(props.row)" class="button is-small is-primary">
            <i class="fas fa-external-link-alt mr-2"></i>
            Play
          </div>
          <div @click="copyGameLink(props.row)" class="button is-small is-primary">
            <i class="fas fa-copy mr-2"></i>
            Copy Game Link
          </div> -->
          <router-link :to="{name:'editSession', params: {sessionId: props.row.id}}" class="button is-small is-primary">
            Manage
            <i class="fas fa-chevron-right ml-2"></i>
          </router-link>
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import ModalBox from '@/components/ModalBox'

export default {
  name: 'SessionsTable',
  components: { ModalBox },
  props: {
    sessions: {
      type: Array,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: true,
      perPage: 20,
      checkedRows: []
    }
  },
  methods: {
    goToGameLink (row) {
      const link = `${this.$store.state.baseUrl}/play/join/${row.adventureId}/${row.id}`
      window.open(link)
    },
    async copyGameLink (row) {
      const link = `${this.$store.state.baseUrl}/play/join/${row.adventureId}/${row.id}`
      await navigator.clipboard.writeText(link)
      this.$buefy.toast.open({
        message: 'Link copied! Paste (ctrl-v) it anywhere.',
        type: 'is-primary',
        queue: false
      })
    }
  }
}
</script>
