<template>
  <article v-if="isActiveTeams" class="message is-danger my-2">
    <div class="message-body danger-background-animation">
      There is at least 1 recent team or player that is active {{ minsSinceLastActive }} minute<span v-if="minsSinceLastActive !== 1">s</span> ago.

      You may change the text, add/remove images and add/edit (not remove) tasks. <br>
      <b>However, we strongly recommend not to add/remove/re-order chapters, or, remove/re-order tasks, as the game may load tasks from the wrong chapter.</b><br>
      You can ignore this warning if the recent teams/players are just for testing.
    </div>
  </article>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'ActiveTeamsWarningMessage',
  data () {
    return {
      isActiveTeams: false,
      minsSinceLastActive: null
    }
  },
  props: ['adventureId'],
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'check-active-teams',
        adventureId: this.adventureId
      }).then(result => {
        this.isActiveTeams = result.data.isActiveTeams
        this.minsSinceLastActive = result.data.minsSinceLastActive
      })
    }
  }
}
</script>

<style>
.danger-background-animation {
  background: linear-gradient(-45deg, white, hsl(0, 100%, 71%), white);
  background-size: 400% 400%;
  animation: gradient-animation 4.5s linear infinite;
}
</style>
