<template>
  <section v-if="profile.isRootAccount" class="section is-main-section">
    <div class="has-text-grey is-size-7 mb-2">
      Click below to select/unselect the admin(s) that can view and edit this game. By default, all admins can view and edit. Root accounts will always have access to all games.
    </div>
    <div>
      <button v-for="admin in admins" :key="admin.id"
              class="is-small mr-2"
              :class="{'button is-primary': visibleTo.includes(admin.id), 'button': !visibleTo.includes(admin.id)}"
              @click="editVisibilities(admin.id)">
        {{ admin.name }}
      </button>
    </div>
  </section>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'AdventureVisibility',
  props: ['adventureId'],
  data () {
    return {
      visibleTo: null, // will be a list of strings (id)
      admins: null // will be a list of objects with .name, .email and .id
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    }
  },
  methods: {
    getVisiblities () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-adventure-visibilities',
        adventureId: this.adventureId
      }).then(result => {
        this.visibleTo = result.data.visibleTo
        this.admins = result.data.admins
      })
    },
    editVisibilities (adminId) {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'edit-adventure-visibilities',
        adventureId: this.adventureId,
        visibleTo: this.visibleTo.includes(adminId) ? this.visibleTo.filter(id => id !== adminId) : [...this.visibleTo, adminId]
      }).then(result => {
        this.getVisiblities()
      })
    }
  },
  mounted () {
    this.getVisiblities()
  }
}
</script>
