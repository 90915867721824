<template>
  <div
  @click="duplicateSession()"
  class="button is-light ml-2"
  :class="{ 'is-loading': loading }"
  >
    <i class="fas fa-clone mr-2"></i>
    Duplicate
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'DuplicateSessionButton',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    duplicateSession () {
      const confirmed = confirm('Duplicating this session will create a new session with the same settings and content. It will NOT duplicate the players or teams. Do you want to continue?')
      if (!confirmed) { return }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'duplicate-session',
        sessionId: this.session.id
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Session duplicated successfully!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
        this.$router.push({ name: 'managePlayTour', params: { adventureId: this.session.adventureId } })
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      })
    }
  }
}
</script>
