<template>
  <card-component title="Edit Admin" icon="fa-user">
    <form v-if="form" @submit.prevent="() => {}">
      <b-field horizontal label="Email" message="Cannot be changed">
        <b-input :value="admin.email" name="email" readonly disabled/>
      </b-field>
      <b-field horizontal label="Name" message="Can only be changed by the admin account user.">
        <b-input :value="admin.name" name="name" readonly disabled/>
      </b-field>
      <b-field horizontal label="Status">
        <b-input :value="admin.status" name="status" readonly disabled />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <div v-if="errorMessage" class="container">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <button
            @click="deleteAdmin"
            class="button is-danger"
            :class="{ 'is-loading': loading }"
          >
            Delete Admin
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'EditAdminForm',
  components: {
    CardComponent
  },
  props: {
    admin: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allowEditProfile: false,
      errorMessage: null,
      loading: false,
      form: {
        name: null,
        email: null
      }
    }
  },
  methods: {
    deleteAdmin () {
      const confirmed = confirm('Confirm deletion?')
      if (!confirmed) {
        return
      }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'delete-member',
        adminUid: this.admin.uid
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully deleted!',
          type: 'is-primary',
          queue: false
        })
        this.$router.push({ name: 'admins' })
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  }
}
</script>
