<template>
  <div>
    <hero-bar>
      View
      <router-link :to="{name:'addAdmins'}" slot="right" class="button is-primary">
        <i class="fas fa-user-plus mr-2"></i>
        Add Admin
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="View Admins"
        icon="fa-users-cog"
      >
        <admins-table
          :tableData="admins"
          :checkable="false"
        />
      </card-component>

    </section>
  </div>
</template>

<script>
import AdminsTable from '@/components/AdminsTable'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'Admins',
  components: {
    HeroBar,
    CardComponent,
    // ClientsTableSample,
    AdminsTable
    // Notification
  },
  computed: {
    admins () {
      return this.$store.state.organisation.members
    }
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
