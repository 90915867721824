<template>
  <div class="my-1">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Lat | Lon | Radius</label>
      </div>
      <div class="field-body field-body-modifier">
        <div class="field-body">
          <div class="button is-dark mr-2" @click="toggleShowSelector">
            <i class="fas fa-map-marked-alt mr-2"></i>
            Open map
          </div>
          <div class="button is-outlined is-danger mr-2" @click="reset">
            <i class="fas fa-eraser"></i>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="lat" v-model="localLat" v-on:blur="checkForErrors()">
            </p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="number" placeholder="lon" v-model="localLon" v-on:blur="checkForErrors()">
            </p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="number" placeholder="radius (metres)" v-model="localRadius" v-on:blur="checkForErrors()">
            </p>
          </div>
        </div>
        <p class="help has-text-grey-light mt-0">
          {{ helptext }}
        </p>
        <div v-if="inputErrorMessage" class="tag is-danger p-2 error-message-modifier ml-2 mt-2">{{ inputErrorMessage }}</div>
      </div>
    </div>
    <div class="modal" :class="{'is-active': showSelector}">
      <div class="modal-background is-clickable" @click="toggleShowSelector"></div>
      <div class="modal-card map-modal-modifier">
        <header class="modal-card-head">
          <p class="modal-card-title">Select Location</p>
          <button class="delete is-clickable" aria-label="close" @click="toggleShowSelector"></button>
        </header>
        <section class="modal-card-body">
          <div class="level mb-2">
            <div class="level-left"></div>
            <div class="level-right">
              <div class="level-item is-flex-direction-column">
                <div class="field has-addons mb-0">
                  <div class="control">
                    <input class="input" style="width: 270px;" v-model="query" type="text" placeholder="Search locations here...">
                  </div>
                  <div class="control">
                    <a @click="processSearchLocation()" class="button is-primary">
                      Go
                    </a>
                  </div>
                </div>
                <!-- <a href="https://locationiq.com" class="help has-text-grey">Search by LocationIQ.com</a> -->
              </div>
            </div>
          </div>
          <div v-if="!userLocation && !map && !localLat && !localLon" class="container has-text-centered">
            <a
            @click="getLocation()"
            class="button has-text-weight-semibold is-small"
            >
              <i class="fas fa-street-view mr-2"></i>
              Get my location
            </a>
          </div>
          <div v-else-if="!map" class="container has-text-centered">
            <a
            @click="initMap()"
            class="button has-text-weight-semibold is-small"
            >
              <i class="fas fa-street-view mr-2"></i>
              Load map
            </a>
          </div>
          <div :id="`map_${_uid}`" class="lat-lon-map"></div>
          <div class="field is-horizontal mt-3" v-if="map">
            <div class="field-label is-normal">
              <label class="label">Radius (metre)</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="number" placeholder="radius (metres)" v-model="localRadius">
                </p>
              </div>
            </div>
          </div>
          <div class="button is-fullwidth is-primary" @click="toggleShowSelector">
            Done
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import L from 'leaflet'

export default {
  name: 'LatLonInput',
  props: ['lat', 'lon', 'radius', 'helptext'],
  data () {
    return {
      showSelector: false,
      clickedGetLocation: false,
      showMap: false,
      map: null,
      marker: null,
      inputErrorMessage: null,
      query: null,
      loading: false
    }
  },
  methods: {
    processSearchLocation () {
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'map-search',
        query: this.query
      }).then(result => {
        this.loading = false
        console.log(result.data)
        if (result.data && result.data.length > 0) {
          this.localLat = Number(result.data[0].lat)
          this.localLon = Number(result.data[0].lon)
          this.changeMapPositions(result.data[0].lat, result.data[0].lon)
        }
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    },
    changeMapPositions (lat, lon) {
      if (!this.map) {
        this.initMap()
      }
      this.map.setView([lat, lon], 45)
      this.marker.setLatLng([lat, lon])
      this.circle.setLatLng([lat, lon])
    },
    updateRecentLatLonRad (latLonRadObj) {
      this.$store.commit('recentLatLonRad', latLonRadObj)
    },
    processLatLonStr (value) {
      if (!value || !isNaN(value)) { return false }
      const latLonStrs = value.split(',')
      if (
        latLonStrs.length === 2 &&
        !isNaN(latLonStrs[0]) &&
        !isNaN(latLonStrs[1])
      ) {
        this.$emit('updateLatLonRad', {
          lat: parseFloat(parseFloat(latLonStrs[0]).toFixed(6)),
          lon: parseFloat(parseFloat(latLonStrs[1]).toFixed(6)),
          radius: this.radius
        })
        return true
      }
      return false
    },
    checkForErrors () {
      if (!this.localLat) {
        this.inputErrorMessage = 'Lat cannot be empty.'
      } else if (!this.localLon) {
        this.inputErrorMessage = 'Lon cannot be empty.'
      } else if (!this.localRadius) {
        this.inputErrorMessage = 'Radius cannot be empty.'
      } else {
        this.inputErrorMessage = null
      }
    },
    reset () {
      this.localLat = null
      this.localLon = null
      this.localRadius = null
      this.$emit('updateLatLonRad', {
        lat: null,
        lon: null,
        radius: null
      })
    },
    toggleShowSelector () {
      this.showSelector = !this.showSelector
      if (!this.showSelector && this.map) {
        this.map.off()
        this.map.remove()
        this.map = null
        this.showMap = false
        if (
          this.localRadius &&
          (!this.localLat || !this.localLon)
        ) {
          this.localRadius = null
          this.$emit('updateLatLonRad', {
            lat: null,
            lon: null,
            radius: null
          })
        }
      }
      if (!this.localRadius) {
        this.localRadius = 50
      }
    },
    getLocation () {
      if (!this.$store.state.userLocation) {
        this.$store.dispatch('getUserLocationAction')
      }
    },
    initMap () {
      this.showMap = true
      let location = [0, 0]
      if (this.$store.state.userLocation) {
        location = [this.$store.state.userLocation.lat, this.$store.state.userLocation.lon]
      }
      if (this.localLat && this.localLon) {
        location = [this.localLat, this.localLon]
      } else if (this.recentLatLonRad) {
        location = [this.recentLatLonRad.lat, this.recentLatLonRad.lon]
      }
      const latLonRadObj = {
        lat: parseFloat(location[0].toFixed(6)),
        lon: parseFloat(location[1].toFixed(6)),
        radius: this.localRadius
      }
      this.$emit('updateLatLonRad', latLonRadObj)
      this.updateRecentLatLonRad(latLonRadObj)
      this.map = L.map(`map_${this._uid}`).setView(location, 45)
      this.tileLayer = L.tileLayer(
        'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=OYQRCyzroGxySflITX5444WUUWwvcUjp'
      )
      if (this.localRadius) {
        this.circleGroup = L.featureGroup()
        this.circle = L.circle(location, this.localRadius).addTo(this.circleGroup)
        this.map.addLayer(this.circleGroup)
      }
      // eslint-disable-next-line new-cap
      const DefaultIcon = new L.icon({
        iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'),
        iconSize: [25, 40],
        iconAnchor: [12.5, 40]
      })
      this.marker = L.marker(location, {
        icon: DefaultIcon,
        draggable: true
      }).addTo(this.map)
      this.tileLayer.addTo(this.map)
      this.marker.on('dragend', () => {
        const fixedLat = parseFloat(this.marker.getLatLng().lat.toFixed(6))
        const fixedLon = parseFloat(this.marker.getLatLng().lng.toFixed(6))
        this.localLat = fixedLat
        this.localLon = fixedLon
        const latLonRadObj = {
          lat: fixedLat,
          lon: fixedLon,
          radius: this.localRadius
        }
        this.$emit('updateLatLonRad', latLonRadObj)
        this.updateRecentLatLonRad(latLonRadObj)
        if (this.map.hasLayer(this.circleGroup)) {
          this.map.removeLayer(this.circleGroup)
          if (this.localRadius) {
            this.circleGroup = L.featureGroup()
            this.circle = L.circle(
              [fixedLat, fixedLon],
              this.localRadius
            ).addTo(this.circleGroup)
            this.map.addLayer(this.circleGroup)
          }
        }
      })
    }
  },
  computed: {
    recentLatLonRad () {
      return this.$store.state.recentLatLonRad
    },
    localLat: {
      get () {
        return this.lat
      },
      set (value) {
        if (!this.processLatLonStr(value)) {
          this.$emit('updateLat', value ? parseFloat(parseFloat(value).toFixed(6)) : null)
        }
      }
    },
    localLon: {
      get () {
        return this.lon
      },
      set (value) {
        if (!this.processLatLonStr(value)) {
          this.$emit('updateLon', value ? parseFloat(parseFloat(value).toFixed(6)) : null)
        }
      }
    },
    localRadius: {
      get () {
        return this.radius ? Number(this.radius) : null
      },
      set (value) {
        this.$emit('updateRadius', value ? Number(value) : null)
      }
    },
    userLocation () {
      return this.$store.state.userLocation
    }
  },
  watch: {
    localRadius (newLocalRadius) {
      if (this.map) {
        if (this.map.hasLayer(this.circleGroup)) {
          this.map.removeLayer(this.circleGroup)
        }
        if (
          newLocalRadius &&
          this.localLat &&
          this.localLon
        ) {
          this.circleGroup = L.featureGroup()
          this.circle = L.circle(
            [this.localLat, this.localLon],
            newLocalRadius
          ).addTo(this.circleGroup)
          this.map.addLayer(this.circleGroup)
        }
      }
    }
  },
  mounted () {
  }
}
</script>

<style>
.map-modal-modifier {
  width: 80% !important;
}

.lat-lon-map {
  height: 500px;
}

.short-map {
  height: 1px !important;
}

.field-body-modifier {
  display: inline-block;
}
</style>
