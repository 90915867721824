<template>
  <div>
    <button class="button is-primary is-small is-outlined" :class="{ 'is-loading': loading }" @click="downloadAnswers">
      <i class="fas fa-check mr-2"></i>
      Download answers
    </button>
    <a ref="downloadLink" :href="csvDownloadURL" download="answers.csv" style="display: none"></a>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import exportToCsv from '@/utils/exportToCsv'

export default {
  name: 'DownloadAdventureAnswersButton',
  props: {
    adventureId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      csvData: [[]], // array of arrays to be downloaded as CSV
      csvDownloadURL: ''
    }
  },
  methods: {
    downloadAnswers () {
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-adventure-answers',
        adventureId: this.adventureId
      })
        .then(result => {
          this.loading = false
          this.csvData = result.data.challengeAnswers
          exportToCsv('download.csv', this.csvData)
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
