<template>
  <div>
    <div class="has-text-grey is-size-7 ml-3 my-2">Data is accurate only when viewed within 90 days of the game.</div>
    <div v-if="!sessionAnalytics" class="container has-text-centered py-6">
      <i class="spinning fas fa-4x fa-circle-notch pointer has-text-grey-light"></i>
      <div class="mt-2 has-text-grey-light">
        Games with large number of players may take longer to load.
      </div>
    </div>
    <b-table
      v-if="sessionAnalytics"
      :data="sessionAnalytics"
      :loading="isLoading"
      :striped="true"
      :hoverable="true"
    >
      <b-table-column field="name" label="Metric" sortable>
        <template v-slot="props">{{ props.row.name }}</template>
      </b-table-column>
      <b-table-column field="value" label="Value" sortable>
        <template v-slot="props">{{ props.row.value }}</template>
      </b-table-column>
    </b-table>

    <hr v-if="challengesInsights">

    <b-table
      v-if="challengesInsights"
      :data="challengesInsights"
      :striped="true"
      :hoverable="true"
    >
      <b-table-column field="name" label="Insight" sortable>
        <template v-slot="props">{{ props.row.name }}</template>
      </b-table-column>
      <b-table-column field="value" label="Tasks" sortable>
        <template v-slot="props">{{ props.row.value }}</template>
      </b-table-column>
    </b-table>

    <hr v-if="challengesAnalytics">

    <b-table
      v-if="challengesAnalytics"
      :data="challengesAnalytics"
      :loading="isLoading"
      :striped="true"
      :hoverable="true"
    >
      <b-table-column field="name" label="Task Name" sortable>
        <template v-slot="props">{{ props.row.name }}</template>
      </b-table-column>
      <b-table-column field="noOfTeamsAttempted" label="Teams Attempted" sortable>
        <template v-slot="props">{{ props.row.noOfTeamsAttempted }}</template>
      </b-table-column>
      <b-table-column field="noOfAttempts" label="Attempts" sortable>
        <template v-slot="props">{{ props.row.noOfAttempts }}</template>
      </b-table-column>
      <b-table-column field="noOfIncorrectAttempts" label="Incorrect Attempts" sortable>
        <template v-slot="props">{{ props.row.noOfIncorrectAttempts }}</template>
      </b-table-column>
      <b-table-column field="noOfSkips" label="Skips" sortable>
        <template v-slot="props">{{ props.row.noOfSkips }}</template>
      </b-table-column>
      <b-table-column field="noOfHints" label="Hints" sortable>
        <template v-slot="props">{{ props.row.noOfHints }}</template>
      </b-table-column>
      <b-table-column field="avgAttemptsPerTeam" label="Avg Attempts/Team" sortable>
        <template v-slot="props">{{ props.row.avgAttemptsPerTeam }}</template>
      </b-table-column>
      <b-table-column field="avgIncorrectAttemptsPerTeam" label="Avg Incorrect Attempts/Team" sortable>
        <template v-slot="props">{{ props.row.avgIncorrectAttemptsPerTeam }}</template>
      </b-table-column>
      <b-table-column field="avgSkipsPerTeam" label="Avg Skips/Team" sortable>
        <template v-slot="props">{{ props.row.avgSkipsPerTeam }}</template>
      </b-table-column>
      <b-table-column field="avgHintsPerTeam" label="Avg Hints/Team" sortable>
        <template v-slot="props">{{ props.row.avgHintsPerTeam }}</template>
      </b-table-column>

    </b-table>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'SessionAnalytics',
  data () {
    return {
      sessionAnalytics: null,
      challengesAnalytics: null,
      challengesInsights: null,
      isLoading: false
    }
  },
  props: {
    sessionId: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.fetchSessionAnalytics()
  },
  methods: {
    fetchSessionAnalytics () {
      this.isLoading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-session-analytics',
        sessionId: this.sessionId
      }).then(result => {
        this.sessionAnalytics = result.data.data
        this.challengesAnalytics = result.data.challengesData
        this.challengesInsights = result.data.challengesInsights
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.error(err)
      })
    }
  }
}
</script>
