<template>
  <div>
    <hero-bar>
      <div @click="$router.go(-1)" class="button is-primary is-light mr-2">
        <i class="fas fa-chevron-left mr-2"></i>
        Manage game
      </div>
      Add Session
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <add-session-form class="tile is-child" />
      </tiles>
    </section>
  </div>
</template>

<script>
import AddSessionForm from '@/components/AddSessionForm'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'

export default {
  name: 'SessionAdd',
  components: {
    HeroBar,
    // ClientsTableSample,
    AddSessionForm,
    Tiles
    // Notification
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
