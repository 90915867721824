<template>
  <div>
    <hero-bar>
      Edit
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <edit-admin-form :admin="admin" class="tile is-child" />
      </tiles>
    </section>
  </div>
</template>

<script>
import EditAdminForm from '@/components/EditAdminForm'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'

export default {
  name: 'AdminEdit',
  components: {
    HeroBar,
    // ClientsTableSample,
    EditAdminForm,
    Tiles
    // Notification
  },
  data () {
    return {
      uid: this.$route.params.uid
    }
  },
  computed: {
    admin () {
      return this.$store.state.organisation.members.filter(el => {
        return el.uid === this.uid
      })[0]
    }
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
