<template>
  <div v-if="session">
    <b-table
      :checked-rows.sync="checkedRows"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="points"
      :data="session.teams"
    >
      <b-table-column label="Name" field="teamName" sortable v-slot="props">
        <div @click="changeTeamName(props.row.teamCode)" class="is-clickable">
          <i class="fas fa-xs fa-edit"></i>
          {{ props.row.name }}
        </div>
      </b-table-column>
      <b-table-column label="Team code" field="teamCode" sortable v-slot="props">
        {{ props.row.teamCode }}
      </b-table-column>
      <b-table-column v-if="hasClans" label="Clan" field="clan" sortable v-slot="props">
        <div @click="changeTeamClan(props.row.teamCode)" class="is-clickable">
          <i class="fas fa-xs fa-edit"></i>
          {{ props.row.clan }}
        </div>
      </b-table-column>
      <b-table-column label="Team instructions" field="url" sortable v-slot="props">
        <div @click="copyInstruction(props.row.teamCode)" class="is-size-7 is-clickable" style="max-width: 240px;">
          <i class="fas fa-copy"></i>
          {{ getInstruction(props.row.teamCode) }}
        </div>
      </b-table-column>
      <b-table-column v-if="hasStageSequenceModifier" label="Chapter Sequence" field="stageSequenceModifier" sortable v-slot="props">
        {{ props.row.stageSequenceModifier }}
      </b-table-column>
      <b-table-column label="Actions"  custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons">
          <!-- <div @click="downloadQr(props.row.teamCode)" class="button is-primary is-light is-small is-clickable">
            <i class="fas fa-qrcode mr-2"></i>
            Download QR
          </div> -->
          <TeamDownloadQrButton :teamCode="props.row.teamCode" :teamName="props.row.name"/>
          <TriggerBookeoEmailButton v-if="session.bookeoProductId || session.name.includes('Bookeo session for')" :teamCode="props.row.teamCode"/>
          <div @click="deleteTeam(props.row.teamCode)" class="button is-danger is-light is-small is-clickable">
            <i class="fas fa-trash"></i>
          </div>
          <div @click="configureStageSequence(props.row.teamCode)" class="button is-primary is-light is-small is-clickable">
            <i class="fas fa-sort-numeric-down"></i>
          </div>
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import TeamDownloadQrButton from '@/components/TeamDownloadQrButton'
import TriggerBookeoEmailButton from '@/components/TriggerBookeoEmailButton'

import firebaseApp from '@/firebase/init'

export default {
  name: 'Teams',
  components: {
    TeamDownloadQrButton,
    TriggerBookeoEmailButton
  },
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      adventureTeams: null,
      isLoading: false,
      paginated: true,
      perPage: 20,
      checkedRows: []
    }
  },
  computed: {
    hasStageSequenceModifier () {
      return this.session.teams.some(team => {
        return team.stageSequenceModifier
      })
    },
    hasClans () {
      return this.session.teams.some(team => {
        return team.clan
      })
    }
  },
  methods: {
    getInstruction (teamCode) {
      return `Open "pyts[dot]link" on your browser and enter ${teamCode} as the game code.`
    },
    async copyInstruction (teamCode) {
      await navigator.clipboard.writeText(
        this.getInstruction(teamCode)
      )
      this.$buefy.toast.open({
        message: 'Copied',
        type: 'is-primary',
        queue: false
      })
    },
    configureStageSequence (teamCode) {
      const teamData = this.session.teams.find(team => team.teamCode === teamCode)
      let promptText = null
      if (teamData.stageSequenceModifier) {
        promptText = `Current chapter sequence is ${teamData.stageSequenceModifier}. Submit a new sequence below or click cancel. To remove custom sequence, type "remove" and click OK.`
      } else {
        promptText = 'Set a custom chapter sequence below, comma separated, starting from 1 all the way to the final chapter number, for example: 3,2,1,4,5'
      }
      const stageSequenceModifier = prompt(promptText)
      if (!stageSequenceModifier) { return }
      this.isLoading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'configure-stage-sequence',
        teamCode,
        stageSequenceModifier
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully configured chapter sequence.',
          type: 'is-primary',
          queue: false
        })
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        alert(err.message)
      })
    },
    changeTeamName (teamCode) {
      const teamData = this.session.teams.find(team => team.teamCode === teamCode)
      const newTeamName = prompt(
        `Current team name is ${teamData.name}. Submit a new team name or click cancel.`
      )
      if (!newTeamName) { return }
      if (this.session.teams.some(team => team.name === newTeamName)) return alert('Cannot use a name that is already taken.')
      this.isLoading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'change-team-name',
        teamCode,
        newTeamName
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully changed team name.',
          type: 'is-primary',
          queue: false
        })
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        alert(err.message)
      })
    },
    changeTeamClan (teamCode) {
      const newClan = prompt('Submit a new clan name or click cancel.')
      if (!newClan) { return }
      this.isLoading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'change-team-clan',
        teamCode,
        newClan
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully changed team clan.',
          type: 'is-primary',
          queue: false
        })
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        alert(err.message)
      })
    },
    deleteTeam (teamCode) {
      const confirmed = confirm('Team will be deleted from this session, click OK to confirm.')
      if (!confirmed) { return }
      this.isLoading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'delete-team',
        teamCode
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully deleted team.',
          type: 'is-primary',
          queue: false
        })
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        alert(err.message)
      })
    },
    downloadQr (teamCode) {

    }
  }
}
</script>
