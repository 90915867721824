<template>
  <div class="is-flex is-justify-content-flex-end mb-1">
    <div @click="downloadCsv()" class="button is-primary" :class="{ 'is-loading': loading }">
      <i class="fas fa-poll mr-2"></i>
      Download results
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'DownloadResults',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    downloadCsv () {
      this.loading = true

      const filename = `playtours_results_${this.session.id}_${new Date().toISOString()}.csv`
      console.log('new Date().getTimezoneOffset() / 60', new Date().getTimezoneOffset() / 60)
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'download-results',
        sessionId: this.session.id,
        timezone: -1 * (new Date().getTimezoneOffset() / 60)
      }).then(result => {
        this.exportToCsv(filename, result.data.csvRows)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    exportToCsv (filename, rows) {
      var processRow = function (row) {
        var finalVal = ''
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? '' : row[j].toString()
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString()
          };
          var result = innerValue.replace(/"/g, '""')
          if (result.search(/("|,|\n)/g) >= 0) { result = '"' + result + '"' }
          if (j > 0) { finalVal += ',' }
          finalVal += result
        }
        return finalVal + '\n'
      }

      var csvFile = ''
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
      }

      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
  }
}
</script>
